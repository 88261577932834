import './App.scss';
import { Routes, Route, Link  } from 'react-router-dom';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './pages/About';
import Contact from './pages/Contact';
import Pigments from './pages/Pigments';
import Ink from './pages/Ink';
import PaintsCoatings from './pages/PaintsCoatings';
import Plastic from './pages/Plastic';
import Textile from './pages/Textile';
import SolventDyes from './pages/SolventDyes';
import Vat from './pages/Vat';
import Pigment from './pages/Pigment';
import Brochure from './pages/Brochure';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-246309855-1";
  ReactGA.initialize(TRACKING_ID);


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/pigments' element={<Pigments/>}/>
        <Route path='/ink' element={<Ink/>}/>
        <Route path='/paints-and-coatings' element={<PaintsCoatings/>}/>
        <Route path='/plastic' element={<Plastic/>}/>
        <Route path='/textile' element={<Textile/>}/>
        <Route path='/solvent' element={<SolventDyes/>}/>
        <Route path='/vat' element={<Vat/>}/>
        <Route path='/pigment' element={<Pigment/>}/>
        <Route path='/brochure' element={<Brochure/>}/>
      </Routes>
    </div>
  );
}

export default App;
