import React from 'react'
import ColumnsTwo from '../ColumnsTwo/ColumnsTwo'
import grad from '../../assets/images/vis.jpg'
import './AboutVision.scss'
import { AboutMain } from '../Data'

function AboutVision() {
  return (
    <div className='vs'>
        <img className='grad' src={grad} alt="" />
        <div className="container-custom-large">
            <div className="vs-wrapper">
                <div className="vs-content">
                    <div className="caption-vs">
                        <p>Goals</p>
                    </div>

                    <div className="title-vs">
                        <h2>
                            Vision and <span>Mission</span>
                        </h2>
                    
                    </div>
                </div>
                <ColumnsTwo titleOne="Vision" contentOne={AboutMain.vision} titleTwo="Mission" contentTwo={AboutMain.mission}/>
            </div>
        </div>
    </div>
  )
}

export default AboutVision