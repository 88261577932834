import React, {useEffect} from 'react'
import Estimate from '../components/Estimate/Estimate'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import HomeAbout from '../components/HomeAbout/HomeAbout'
import HomeHero from '../components/HomeHero/HomeHero'
import QC from '../components/QC/QC'
import RnD from '../components/RnD/RnD'
import ReactGA from 'react-ga'
// import s from '../components/Canvasgradient';

function Home() {
  useEffect(()=> {
    ReactGA.pageview(window.location.pathname)
}, [])
  return (
    <div>
        <Header/>
        <HomeHero/>
        <HomeAbout/>
        <RnD/>
        <QC/>
        <Estimate/>
        <Footer/>
    </div>
  )
}

export default Home