import React from 'react'
import './Button.scss';

function Button(props) {
  return (
    <a href={props.link}>
      <div className='button-custom' style={{borderColor: props.border}}>
        <p style={{color: props.color}}>{props.text}</p>
      </div>
    </a>
    
  )
}

export default Button