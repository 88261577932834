import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/Button'
import qcbg from '../../assets/images/qcbg.png'
import can from '../../assets/images/paintcan.png'
import './QC.scss'

function QC() {
  return (
    <div className='qc'>
        <img className='qcbg' src={qcbg} alt="" />
        <img className='can' src={can} alt="" />
        <div className="container-custom-large">
            <div className="qc-wrapper">
                <div className="qc-wrapper-content">
                    <Row>
                        <Col className='col-over' xs={12} md={8}>
                            <div className="caption">
                                <p>
                                    Expertise
                                </p>
                            </div>

                            <div className="title">
                                <h2>
                                    Quality <span>Control</span> 
                                </h2>
                            </div>

                            <div className="bp-wrapper">
                                <div className="bp-point">
                                    <ul>
                                        <li>
                                            <h3>
                                              Color Variation Levels
                                            </h3>
                                            <p>
                                              Thanks to Research and development unit, our color variation levels (0.7 Delta E) are considerably lower than the accepted world standard in variation (1 Delta E).
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="bp-wrapper">
                                <div className="bp-point">
                                    <ul>
                                        <li>
                                            <h3>
                                               Quality Personnel
                                            </h3>
                                            <p>
                                            Management is directly involved in the selection of QC, internal and external quality control personnel. which helps deliver fault-free products with on-time delivery.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="bp-wrapper">
                                <div className="bp-point">
                                    <ul>
                                        <li>
                                            <h3>
                                                Consistence in Quality
                                            </h3>
                                            <p>
                                                Quality is a never-ending process at Parshwanath, and it never takes a break or a backward glance.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        {/* <Col xs={0} md={4}>
                        </Col> */}

                    </Row>

                    <div className="button-wrapper">
                        <Button text="Know More" link="/about" color="#081120" border="#EA2778"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default QC