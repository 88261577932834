import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../Button/Button'
import './ColumnsTwo.scss'

function ColumnsTwo(props) {
  return (
    <div className='c2'>
        <Row>
            <Col xs={12} md={6}>
                <div className="c2-content-wrapper">
                    
                    <div className="c2-content">
                        <ul>
                            <li>
                                <div className="title">
                                    <h2>{props.titleOne}</h2>
                                </div>
                            </li>
                        </ul>
                        
                        <div className="text">
                            <p>
                                {props.contentOne}
                            </p>
                        </div>
                    </div>
                </div>
            </Col>

            <Col xs={12} md={6}>
                <div className="c2-content-wrapper">
                    {/* <div className="dot">
                        <span></span>
                    </div> */}
                    <div className="c2-content">
                        <ul>
                            <li>
                                <div className="title">
                                    <h2>{props.titleTwo}</h2>
                                </div>
                            </li>
                        </ul>

                        <div className="text">
                            <p>
                                {props.contentTwo}
                            </p>
                        </div>
                    </div>
                </div>
                
            </Col>
        </Row>
    </div>
  )
}

export default ColumnsTwo