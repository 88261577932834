import React from 'react'
import Button from '../Button/Button'
// import estbg from '../../assets/images/estbg.png'
import './Estimate.scss'

function Estimate() {
  return (
    <div className='est'>
        <div className="est-wrapper">
            <h2>Let's Get You an <br/>Estimate</h2>
            <Button link="/contact" color="#fff" text="Contact Us" border="#fff"/>
        </div>
    </div>
  )
}

export default Estimate