import React from 'react'
import './PigmentBox.scss'

function PigmentBox(props) {
  return (
    <div className="pr-card">
        {/* <div className="pr-card-top" style={{backgroundColor: props.color}}>Lorem Ipsum</div> */}
            <div className="color-bg" style={{backgroundColor: props.color}}></div>

            <div className="pr-card-content">
                <div className="color-name">
                    <p>
                        {props.name}
                    </p>
                </div>
                {/* <div className="color-text">
                    <p>
                        Lorem ipsum dolor <span style={{color: props.color}}>sit amet</span>, consectetur adipiscing elit, 
                    </p>
                </div> */}

                <div className="color-link">
                    <button style={{borderColor: props.color}}>
                        Download
                    </button>
                </div>
            </div>
        
    </div>
  )
}

export default PigmentBox