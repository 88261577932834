import React, {useEffect} from 'react'
import pdf from '../assets/images/parshwnath.pdf'
import { Document, Page } from "react-pdf/dist/umd/entry.webpack"
import '../components/Brochure.scss'
import ReactGA from "react-ga"

function Brochure() {

    useEffect(()=> {
        ReactGA.pageview(window.location.pathname)
    }, [])

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Document file={pdf}>
            <Page pageNumber={1} />
        </Document>

        <Document file={pdf}>
            <Page pageNumber={2} />
        </Document>

        <Document file={pdf}>
            <Page pageNumber={3} />
        </Document>

        <Document file={pdf}>
            <Page pageNumber={4} />
        </Document>
    </div>
    
  )
}

export default Brochure