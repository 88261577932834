import React, {useEffect} from 'react'
import AboutHistory from '../components/AboutHistory/AboutHistory'
import AboutStory from '../components/AboutStory/AboutStory'
import AboutVision from '../components/AboutVision/AboutVision'
import Estimate from '../components/Estimate/Estimate'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Hero from '../components/Hero/Hero'
import { HeroCaption } from '../components/Data'
import ReactGA from 'react-ga'
function About() {
  useEffect(()=> {
    ReactGA.pageview(window.location.pathname)
}, [])
  return (
    <div>
        <Header/>
        <Hero title="About Us" caption={HeroCaption.about}/>
        <AboutStory/>
        <AboutVision/>
        <AboutHistory/>
        <Estimate/>
        <Footer/>
    </div>
  )
}

export default About