import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './ContactForm.scss'

function ContactForm() {
  return (
    <div className='cf'>
        <div className="container-custom-large">
            <div className="cf-wrapper">
                <Row>
                    <Col xs={12} lg={7}>
                        <div className="caption">
                            <p>
                                Contact Us
                            </p>
                        </div>

                        <div className="title">
                            <h2>
                                Write To <span>Us</span>
                            </h2>
                        </div>

                        <div className="form-custom">
                            
                        </div>

                        <div className="form">
                            <div className="input-row">
                                <div className="input-item">
                                    <input type="text" placeholder='name' name='name' required />
                                </div>

                                <div className="input-item">
                                    <input type="email" placeholder='email' name='email' required />
                                </div>
                            </div>

                            <div className="input-row">
                                <div className="input-item">
                                    <input type="tel" placeholder='Number' name='number' required />
                                </div>

                                <div className="input-item">
                                    <input type="text" placeholder='subject' name='subject' required />
                                </div>
                            </div>

                            <div className="input-row">
                                <textarea placeholder='message' rows={4}/>
                            </div>

                            <div className='button-custom' >
                                <p>Submit</p>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} lg={5}>
                        <div className="git">
                            <div className="git-title">
                                <h3>
                                    Get in Touch
                                </h3>
                            </div>
                            <div className="git-content">
                                <div className="git-row">
                                    <p>Mail us : </p>
                                    <p>parshwnath@parshwnath.com</p>
                                </div>

                                <div className="git-row">
                                    <p>Mail us : </p>
                                    <p>+91 07935333214</p>
                                </div>

                                <div className="git-row">
                                    <p>Drop&nbsp;by&nbsp;: </p>
                                    <p>THE FIRST, Block A, 8th Floor, Behind Keshavbaugh Party Plot,
                                        <br/>Vastrapur, Ahmedabad, Gujarat - 380015
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                
                
            </div>
        </div>
        <Row>
            <Col xs={12}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.8588404356587!2d72.52880741537628!3d23.028954621867463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8554457b7919%3A0x569334da157e4306!2sParshwanath%20Dyechem%20Industries%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1665649167612!5m2!1sen!2sin" width="100%" height="650" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Col>
        </Row>
    </div>
  )
}

export default ContactForm