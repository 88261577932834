import React from 'react'
import ContactForm from '../components/ContactForm/ContactForm'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Hero from '../components/Hero/Hero'

function Contact() {
  return (
    <div>
        <Header/>
        <Hero title='Contact Us'/>
        <ContactForm/>
        <Footer/>
    </div>
  )
}

export default Contact