import React from 'react'
import './HomeAbout.scss'
import { Col, Row } from 'react-bootstrap'
import Button from '../Button/Button'
import image from '../../assets/images/unnamed-4.png'
import pigment from '../../assets/images/pigment.webp'
import text from '../../assets/images/text.jpeg'
import circleTwo from '../../assets/images/circle-2.png'
import card from '../../assets/images/card.png'
import square from '../../assets/images/square.png'


function HomeAbout() {
  return (
    <div className='home-about'>
        <div className="pull-down-tab">
            scroll
        </div>
        <div className="container-custom-large">
            <div className="home-about-wrapper">            
                
                <div className="home-about-content">
                    <Row>
                        <Col xs={12} md={7}>
                            <div className="caption">
                                {/* <span></span> */}
                                <p>Know Us</p>
                            </div>
                            <div className="title">
                                <h2>
                                    A little About<br/><span>Parshwanath's</span> History
                                </h2>
                            </div>
                        </Col>

                        <Col xs={12} md={5}>
                            <div className="text">
                                <p>
                                Parshwanath Dyechem is one of the most successful companies in the pigment and dye manufacturing industries. We have been providing quality products and services to our clients for more than three decades. The growth we experienced throughout the years gave us a better understanding of the market and customer needs, which led us to open our first manufacturing plant in 1992, producing Phthalocyanine Green as our first product.
                                </p>
                            </div>
                            <Button link="/about" text="Know More" color="#fff" border="#A7D309"/>
                        </Col>
                    </Row>
                </div>

                <div className="home-about-images">
                    <img src={image} alt="" />
                </div>

                <div className="home-products">
                    <div className="home-products-content">
                        <Row>
                            {/* <span className='dot'></span> */}
                            <Col xs={12} md={7}>
                                <div className="caption">
                                    {/* <span></span> */}
                                    <p>Products</p>
                                       
                                </div>
                                <div className="title">
                                    
                                    <h2>
                                        What we<br/><span>Manufacture</span> 
                                    </h2>
                                        
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="home-products-card">
                        <img className='circle-two' src={circleTwo} alt="" />
                        <img className='square' src={square} alt="" />
                        <Row>
                            <Col xs={12} md={4}>
                                <a href="/solvent">
                                <div className="home-product-card">
                                    <span>Solvent Dyes</span>
                                    <img src={card} alt="" />
                                </div>
                                </a>
                            </Col>

                            <Col xs={12} md={4}>
                                <a href='/pigment'>
                                    <div className="home-product-card">
                                        <span>Pigment</span>
                                        <img src={pigment} alt="" />
                                    </div>
                                </a>
                            </Col>

                            <Col xs={12} md={4}>
                                <a href="/vat">
                                    <div className="home-product-card">
                                        <span>Vat Dyes</span>
                                        <img src={text} alt="" />
                                    </div>
                                </a>
                            </Col>
                        </Row>

                        <div className="home-products-button">
                            <Button text="Know More" color="#ffffff" border="#A7D309"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeAbout