import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Hero from '../components/Hero/Hero'
import PigmentRow from '../components/PigmentRow/PigmentRow'

function Ink() {
  return (
    <div>
      <Header/>
      <Hero title="Inks"/>
      <PigmentRow/>
      <Footer/>
    </div>
  )
}

export default Ink