import React, {useEffect, useState} from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Hero from '../components/Hero/Hero'
import { boxes, HeroCaption } from '../components/Data'
import PigmentBox from '../components/PigmentBox/PigmentBox'
import { Col, Row } from 'react-bootstrap'
import '../components/Pigments.scss'

function Pigment() {

const [tag, setTag] = useState('pigment');
  // const [active, setActive] = useState(false);
  const [filteredBoxes, setFilteredBoxes] = useState([]);

  useEffect(() => {
    {tag ==='pigment' ? setFilteredBoxes(boxes.filter(box => box.type === tag)) : setFilteredBoxes(boxes) }
  }, [])

  return (
    <div>
        <Header/>
        <Hero title="Pigments"/>

        <div className='container-custom-large'>
            <div className='for-margin'>
                <Row>
                  
                {filteredBoxes.map(box => 
                    <Col key={box.id} xs={6} md={3}>
                    <PigmentBox name={box.colorName} color={box.color}/>
                    </Col>
                    )}
                </Row>
            </div>
          
        </div>
        <Footer/>
    </div>
  )
}

export default Pigment