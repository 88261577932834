import React from 'react'
import logo from '../../assets/images/logo.png'
import { Row, Col } from 'react-bootstrap'
import './Footer.scss'

function Footer() {
  return (
    <div className='footer-custom'>
        <div className="container-custom-large">
            <div className="footer-wrapper">
                <div className="footer-left">
                    <Row>
                        <Col xs={12}>
                            <div className="footer-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="footer-content">
                                <p>Mail us: parshwnath@parshwnath.com</p>
                                <p>Call us: +91 0793533214</p>
                                <p>Drop by: THE FIRST, 8th floor, <br/>Near Keshavbaugh Party Plot, <br/>Vastrapur, Ahmedabad-380015</p>
                            </div>

                            <div className="social-links">
                                <div className="facebook">
                                    <p>fb |</p>
                                </div>
                                <div className="instagram">
                                    <p>In</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="footer-right">
                    <Row>
                        {/* <Col xs={12} md={3}>
                            <div className="footer-title">
                                <h3>Pages</h3>
                            </div>

                            <div className="footer-links">
                                <p>Home</p>
                                <p>About Us</p>
                                <p>Products</p>
                                <p>Quality Control</p>
                                <p>RnD</p>
                            </div>
                        </Col> */}
                        <Col xs={12} md={4}>
                            <div className="footer-title">
                                <h3>Products</h3>
                            </div>

                            <div className="footer-links">
                                <a href='/solvent'>
                                    <p>Solvent Dyes</p>
                                </a>
                                <a href='/vat'>
                                    <p>Vat Dyes</p>
                                    
                                </a>

                                <a href='/pigment'> 
                                    <p>Pigments</p>
                                </a>
                                
                                {/* <p>Quality Control</p>
                                <p>RnD</p> */}
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="footer-title">
                                <h3>Support</h3>
                            </div>

                            <div className="footer-links">
                                <p>Privacy Policy</p>
                                <p>Terms and Conditions</p>
                                {/* <p>Products</p> */}
                                <p>Quality Control</p>
                                <p>RnD</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="footer-title">
                                <h3>Links</h3>
                            </div>

                            <div className="footer-links">
                                <a href="/">
                                    <p>Home</p>
                                </a>

                                <a href="/about">
                                    <p>About</p>
                                </a>

                                <a href="/pigments">
                                    <p>Pigment Finder</p>
                                </a>

                                <a href="/contact">
                                 <p>Contact Us</p>

                                </a>
                                {/* <p>RnD</p> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="footer-copy">
                <p>Copyright by Parshwanath @ 2022</p>
                <p>Web Designed By Logion Solutions</p>
            </div>
        </div>
    </div>
  )
}

export default Footer