import React, {useEffect, useState, useRef} from 'react'
import { NavLink } from 'react-bootstrap'
import { Spiral } from 'hamburger-react'
import logo from '../../assets/images/logo.png'
import gsap from 'gsap'
import './Header.scss'

function Header() {

    const menu = useRef()

    const [nav, setNav] = useState(false);

    const toggleHam = () => {
        setNav(!nav);
    }

    gsap.set(menu.current, {
        opacity: 0,
        display: 'none'
    })

    if(nav) {
        gsap.to(menu.current, {
            // y: '0px',
            opacity: 1,
            display: 'flex'
        })
    }   

    if(!nav) {
        gsap.to(menu.current, {
            // y: '-100px',
            opacity: 0
        })
    }   

  return (
    <div className='header'>
            <a href='/'>
                <div className="header-logo">
                    <img src={logo} alt="" />
                </div>
            </a>
                
        <div className="container-custom-large">
                
            <div className="header-wrapper">
                <div className="header-menu">
                    <div className="hamburger-icon" onClick ={toggleHam}>
                        <Spiral />
                    </div>
                    <div className="header-menu-list" ref={menu}>
                        <div className="menu-item">
                            <NavLink href='/pigments' >
                                <p>
                                    Pigment Finder
                                </p>
                            </NavLink>
                           
                        </div>
                        <div className="menu-item">
                            <NavLink href='/about'>
                                <p>
                                    About Us
                                </p>
                            </NavLink>
                        </div>
                        <div className="menu-item">
                            <NavLink href='/contact'>
                                <p>
                                    Contact
                                </p>
                            </NavLink>
                        </div>
                    </div>
                </div>
                
                <div className="header-contact">
                    <div className="header-contact-links">
                        <p>In |</p>
                        <p>Fb</p>
                    </div>
                    {/* <div className="header-contact-button">
                        <p>Contact</p>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header