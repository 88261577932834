import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/Button'
import ColumnsTwo from '../ColumnsTwo/ColumnsTwo'
import { rndData } from '../Data'
import './RnD.scss'

function RnD() {
  return (
    <div className='rnd'>
        <div className="container-custom-large">
            <div className="rnd-wrapper">
                <div className="rnd-content">
                    <Row>
                        <Col xs={12}>
                            <div className="caption">
                                <p>Expertise</p>
                            </div>

                            <div className="title">
                                <h2>Research And <br/> <span>Development</span></h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="text">
                                <p>
                                  Parshwnath thrives to provide the best possible service to its customers by meeting their specific needs. Our active R&D division is constantly working to bring new products with efficient production lines. Our high-quality products have received great response in India as well as around the world. Our thirst for success drives us to constantly update and improve our skills and technology. Parshwnath's remarkable consistency is due, in large part, to the constant research and development that goes into the company.
                                </p>
                            </div>
                        </Col>
                    </Row>

                </div>

                <ColumnsTwo 
                titleOne={rndData.titleOne} contentOne={rndData.contentOne}
                titleTwo={rndData.titleTwo} contentTwo={rndData.contentTwo}
                />
                <div className="button-wrapper">
                    <Button link="/about" color="#081120" border="#7609A8" text="Know More"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RnD