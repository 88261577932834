import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './PigmentRow.scss'

function PigmentRow(props) {
  return (
    <div className='pr'>
        <div className="container-custom-large">
            <div className="pr-wrapper">
                <Row>
                    <Col xs={12} md={3}>
                        <div className="pr-card">
                            <div className="pr-card-top" style={{backgroundColor: props.color}}>Lorem Ipsum</div>
                                <div className="pr-card-content">
                                    <div className="color-name">
                                        <p>
                                            Lorem ipsum
                                        </p>
                                    </div>
                                    <div className="color-text">
                                        <p>
                                            Lorem ipsum dolor <span style={{color: props.color}}>sit amet</span>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>

                                    <div className="color-link">
                                        <button style={{borderColor: props.color}}>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            
                        </div>
                    </Col>

                    <Col xs={12} md={3}>
                        <div className="pr-card">
                            <div className="pr-card-top" style={{backgroundColor: props.color}}>Lorem Ipsum</div>
                                <div className="pr-card-content">
                                    <div className="color-name">
                                        <p>
                                            Lorem ipsum
                                        </p>
                                    </div>
                                    <div className="color-text">
                                        <p>
                                            Lorem ipsum dolor <span style={{color: props.color}}>sit amet</span>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>

                                    <div className="color-link">
                                        <button style={{borderColor: props.color}}>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            
                        </div>
                    </Col>

                    <Col xs={12} md={3}>
                        <div className="pr-card">
                            <div className="pr-card-top" style={{backgroundColor: props.color}}>Lorem Ipsum</div>
                                <div className="pr-card-content">
                                    <div className="color-name">
                                        <p>
                                            Lorem ipsum
                                        </p>
                                    </div>
                                    <div className="color-text">
                                        <p>
                                            Lorem ipsum dolor <span style={{color: props.color}}>sit amet</span>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>

                                    <div className="color-link">
                                        <button style={{borderColor: props.color}}>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            
                        </div>
                    </Col>

                    <Col xs={12} md={3}>
                        <div className="pr-card">
                            <div className="pr-card-top" style={{backgroundColor: props.color}}>Lorem Ipsum</div>
                                <div className="pr-card-content">
                                    <div className="color-name">
                                        <p>
                                            Lorem ipsum
                                        </p>
                                    </div>
                                    <div className="color-text">
                                        <p>
                                            Lorem ipsum dolor <span style={{color: props.color}}>sit amet</span>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </div>

                                    <div className="color-link">
                                        <button style={{borderColor: props.color}}>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default PigmentRow