import React, {useEffect, useState} from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Hero from '../components/Hero/Hero'
import PigmentBox from '../components/PigmentBox/PigmentBox'
import {Row, Col} from 'react-bootstrap'
import {boxes} from '../components/Data'

function Vat() {
    const [tag, setTag] = useState('vat');
    // const [active, setActive] = useState(false);
    const [filteredBoxes, setFilteredBoxes] = useState([]);
  
    useEffect(() => {
      {tag ==='vat' ? setFilteredBoxes(boxes.filter(box => box.type === tag)) : setFilteredBoxes(boxes) }
    }, [])
  
    return (
      <div>
          <Header/>
          <Hero title="Vat Dyes"/>
  
          <div className='container-custom-large'>
              <div className='for-margin'>
                  <Row>
                  {filteredBoxes.map(box => 
                      <Col key={box.id} xs={6} md={3}>
                      <PigmentBox name={box.colorName} color={box.color}/>
                      </Col>
                      )}
                  </Row>
              </div>
            
          </div>
          <Footer/>
      </div>
    )
}

export default Vat