import React from 'react'
import './AboutHistory.scss'
import ColumnsTwo from '../ColumnsTwo/ColumnsTwo'

function AboutHistory() {
  return (
    <div className='abh'>
        <div className="container-custom-large">
            <div className="abh-wrapper">
                <div className="abh-content">
                    <div className="caption">
                        <p>
                            Milestones
                        </p>
                    </div>

                    <div className="title">
                        <h2>
                            Parshwanath <span>History</span>
                        </h2>
                    </div>
                </div>

                <div className="col-wrapper">
                    <ColumnsTwo
                        titleOne="Year -1980"
                        contentOne="Established Parshwnath with objective of of trading activities, dealing in Solvent Dyes. "

                        titleTwo="Year -1992"
                        contentTwo="Started manufacturing with it’s flagship product - Phthalocyanine Green 7."
                    />
                </div>
                <div className="col-wrapper">
                    <ColumnsTwo
                        titleOne="Year -1995"
                        contentOne="Established it’s first government recognised plant, along with a state-of-the-art R&D facility."

                        titleTwo="Year -1996"
                        contentTwo="Established an advanced Effluent Treatment Plant in Ahmedabad."
                    />
                </div>
                <div className="col-wrapper">
                    <ColumnsTwo
                        titleOne="Year -1997"
                        contentOne="Introduction of Solvent Dyes into the market and expansion of Phthalocyanine Green 7 & Green 36
                        production capacity."

                        titleTwo="Year -2000"
                        contentTwo="Parshwanath in the year 2000, introduced liquid Solvent Dyes to it’s product line."
                    />
                </div>

                <div className="col-wrapper">
                    <ColumnsTwo
                        titleOne="Year -2009"
                        contentOne="Parshwanath also got into manufacturing of High Performance Pigments."

                        titleTwo="Year -2000"
                        contentTwo="Parshwanath in the year 2000, introduced liquid Solvent Dyes to it’s product line."
                    />
                </div>

                <div className="col-wrapper">
                    <ColumnsTwo
                        titleOne="Year -2017"
                        contentOne="Expanded the production of Phthalocynine Pigment
                        in the year 2017."

                        titleTwo="Year -2020"
                        contentTwo="Parshwanath established a plant for the manufacturing of Intermediate and Vat Blue."
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutHistory