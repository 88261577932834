export const boxes = [
    {id: '1', colorName: 'PARLITE YELLOW KM 10 - 39', ci: 'Solvent Yellow 14', tag:"yellow", appl: 'plastic', color:'#FFA54B', type:'solvent'},
    {id: '2', colorName: 'PARLITE YELLOW KM 10 - 43', ci: 'Solvent Yellow 18', tag:"yellow", appl: 'plastic', color:'#ECE24D', type:'solvent'},
    {id: '3', colorName: 'PARLITE YELLOW KM 10 - 58', ci: 'Solvent Yellow33', tag:"yellow", appl: 'plastic', color:'#E4E64D', type:'solvent'},
    {id: '4', colorName: 'PARLITE YELLOW KMFT 10 - 68', ci: 'Solvent Yellow 43', tag:"yellow", appl: 'plastic', color:'#EEFA10', type:'solvent'},
    {id: '5', colorName: 'PARLITE YELLOW KMFT 10 - 69', ci: 'Solvent Yellow 44', tag:"yellow", appl: 'plastic', color:'#C6D500', type:'solvent'},
    {id: '6', colorName: 'PARLITE YELLOW KM 10 - 97', ci: 'Solvent Yellow 72', tag:"yellow", appl: 'plastic', color:'#FFCD33', type:'solvent'},
    {id: '7', colorName: 'PARLITE YELLOW KM 10 - 118', ci: 'Solvent Yellow 93', tag:"yellow", appl: 'plastic', color:'#F4E907', type:'solvent'},
    {id: '8', colorName: 'PARLITE YELLOW KM 10 - 89', ci: 'Solvent Yellow 114', tag:"yellow", appl: 'plastic', color:'#FFF728', type:'solvent'},
    {id: '9', colorName: 'PARLITE YELLOW KMFT 10-106', ci: 'Solvent Yellow 131', tag:"yellow", appl: 'plastic', color:'#E2FEA9', type:'solvent'},
    {id: '10', colorName: 'PARLITE YELLOW KM 10-122', ci: 'Pigment Yellow 147', tag:"yellow", appl: 'plastic', color:'#F7C327', type:'solvent'},
    {id: '11', colorName: 'PARLITE YELLOW KM 10 - 132', ci: 'Solvent Yellow 157', tag:"yellow", appl: 'plastic', color:'#F3F569', type:'solvent'},
    {id: '12', colorName: 'PARLITE YELLOW KMFT 10-135', ci: 'Solvent Yellow 160.1', tag:"yellow", appl: 'plastic', color:'#F3FF0A', type:'solvent'},
    {id: '13', colorName: 'PARLITE YELLOW KM 10 - 138', ci: 'Solvent Yellow 163', tag:"yellow", appl: 'plastic', color:'#F6D32F', type:'solvent'},
    {id: '14', colorName: 'PARLITE YELLOW KM 10 - 142', ci: 'Solvent Yellow 167', tag:"yellow", appl: 'plastic', color:'#FFF001', type:'solvent'},
    {id: '15', colorName: 'PARLITE YELLOW KMFT 10 - 147', ci: 'Solvent Yellow 172', tag:"yellow", appl: 'plastic', color:'#FFF001', type:'solvent'},
    {id: '16', colorName: 'PARLITE YELLOW KMFT 10 - 160', ci: 'Solvent Yellow 185', tag:"yellow", appl: 'plastic', color:'#F4FF0A', type:'solvent'},
    {id: '17', colorName: 'PARLITE YELLOW KM 10-176', ci: 'Disperse Yellow 201', tag:"yellow", appl: 'plastic', color:'#D9FB71', type:'solvent'},
    {id: '18', colorName: 'PARLITE YELLOW KM P - 10', ci: 'PROP.', tag:"yellow", appl: 'plastic', color:'#FFFF00', type:'solvent'},
    {id: '19', colorName: 'PARLITE YELLOW KM 110', ci: 'PROP.', tag:"yellow", appl: 'plastic', color:'#FFFF00', type:'solvent'},
    {id: '20', colorName: 'PARLITE YELLOW KM 120', ci: 'PROP.', tag:"yellow", appl: 'plastic', color:'#FFFF00', type:'solvent'},
    {id: '22', colorName: 'PARLITE ORANGE KM 50 - 72', ci: 'Disperse Orange 47', tag:"orange", appl: 'plastic', color:'#FFA500', type:'solvent'},
    {id: '23', colorName: 'PARLITE ORANGE KM 50 - 85', ci: 'Solvent Orange 60', tag:"orange", appl: 'plastic', color:'#FE9834', type:'solvent'},
    {id: '24', colorName: 'PARLITE ORANGE KMFT 50 - 38', ci: 'Solvent Orange 63', tag:"orange", appl: 'plastic', color:'#FE7C56', type:'solvent'},
    {id: '25', colorName: 'PARLITE ORANGE KM 50 - 80', ci: 'Solvent Orange 105', tag:"orange", appl: 'plastic', color:'#E46F10', type:'solvent'},
    {id: '26', colorName: 'PARLITE RED KM 20 - 48', ci: 'Solvent Red 23', tag:"red", appl: 'plastic', color:'#E43228', type:'solvent'},
    {id: '27', colorName: 'PARLITE RED KM 20 - 49', ci: 'Solvent Red 24', tag:"red", appl: 'plastic', color:'#D51B27', type:'solvent'},
    {id: '28', colorName: 'PARLITE RED KM 20 - 77', ci: 'Solvent Red 52', tag:"red", appl: 'plastic', color:'#B51669', type:'solvent'},
    {id: '29', colorName: 'PARLITE RED KM 20 - 86', ci: 'Solvent Red 111', tag:"red", appl: 'plastic', color:'#ED5141', type:'solvent'},
    {id: '30', colorName: 'PARLITE RED KM 20 - 110', ci: 'Solvent Red 135', tag:"red", appl: 'plastic', color:'#EF4743', type:'solvent'},
    {id: '31', colorName: 'PARLITE RED KMFT 20 - 124', ci: 'Solvent Red 149', tag:"red", appl: 'plastic', color:'#FF4A89', type:'solvent'},
    {id: '32', colorName: 'PARLITE RED KMFT 20 - 125', ci: 'Solvent Red 150', tag:"red", appl: 'plastic', color:'#FF5B84', type:'solvent'},
    {id: '33', colorName: 'PARLITE RED KM 20 - 143', ci: 'Solvent Red 168', tag:"red", appl: 'plastic', color:'#FB485E', type:'solvent'},
    {id: '34', colorName: 'PARLITE RED KM 20 - 144', ci: 'Solvent Red 169', tag:"red", appl: 'plastic', color:'#F13C52', type:'solvent'},
    {id: '35', colorName: 'PARLITE RED KM 20 - 145', ci: 'Solvent Red 170', tag:"red", appl: 'plastic', color:'#B73326', type:'solvent'},
    {id: '36', colorName: 'PARLITE RED KM 20 - 146', ci: 'Solvent Red 171', tag:"red", appl: 'plastic', color:'#571717', type:'solvent'},
    {id: '37', colorName: 'PARLITE RED KM 20 - 147', ci: 'Solvent Red 172', tag:"red", appl: 'plastic', color:'#CC3301', type:'solvent'},
    {id: '38', colorName: 'PARLITE RED KM 20 - 154', ci: 'Solvent Red 179', tag:"red", appl: 'plastic', color:'#E55335', type:'solvent'},
    {id: '39', colorName: 'PARLITE RED KM 20 - 171', ci: 'Solvent Red 196', tag:"red", appl: 'plastic', color:'#DA2824', type:'solvent'},
    {id: '40', colorName: 'PARLITE RED KM 20 - 172', ci: 'Solvent Red 197', tag:"red", appl: 'plastic', color:'#DC2251', type:'solvent'},
    {id: '41', colorName: 'PARLITE RED KM 20 - 182', ci: 'Solvent Red 207', tag:"red", appl: 'plastic', color:'#DC1B5C', type:'solvent'},
    {id: '42', colorName: 'PARLITE RED KM 2NN', ci: 'PROP.', tag:"red", appl: 'plastic', color:'#FF0000', type:'solvent'},
    {id: '43', colorName: 'PARLITE RED KM 100', ci: 'PROP.', tag:"red", appl: 'plastic', color:'#FF0000', type:'solvent'},
    {id: '44', colorName: 'PARLITE GREEN KM 60 - 28', ci: 'Solven Green 3', tag:"green", appl: 'plastic', color:'#005C78', type:'solvent'},
    {id: '45', colorName: 'PARLITE GREEN KM 60 - 53', ci: 'Solven Green 28', tag:"green", appl: 'plastic', color:'#087D3C', type:'solvent'},
    {id: '46', colorName: 'PARLITE BLUE KM 40 - 60', ci: 'Solvent Blue 35', tag:"blue", appl: 'plastic', color:'#033DB2', type:'solvent'},
    {id: '47', colorName: 'PARLITE BLUE KM 40 - 61', ci: 'Solvent Blue 36', tag:"blue", appl: 'plastic', color:'#0527A2', type:'solvent'},
    {id: '48', colorName: 'PARLITE BLUE KM 40 - 83', ci: 'Solvent Blue 58', tag:"blue", appl: 'plastic', color:'#343399', type:'solvent'},
    {id: '49', colorName: 'PARLITE BLUE KM 40 - 84', ci: 'Solvent Blue 59', tag:"blue", appl: 'plastic', color:'#031496', type:'solvent'},
    {id: '50', colorName: 'PARLITE BLUE KM 40 - 92', ci: 'Solvent Blue 67', tag:"blue", appl: 'plastic', color:'#004D81', type:'solvent'},
    {id: '51', colorName: 'PARLITE BLUE KM 40 - 122', ci: 'Solvent Blue 97', tag:"blue", appl: 'plastic', color:'#0A31AD', type:'solvent'},
    {id: '52', colorName: 'PARLITE BLUE KM 40 - 76', ci: 'Solvent Blue 101', tag:"blue", appl: 'plastic', color:'#014563', type:'solvent'},
    {id: '53', colorName: 'PARLITE BLUE KM 40 - 77', ci: 'Solvent Blue 102', tag:"blue", appl: 'plastic', color:'#5A00FE', type:'solvent'},
    {id: '54', colorName: 'PARLITE BLUE KM 40 - 79', ci: 'Solvent Blue 104', tag:"blue", appl: 'plastic', color:'#0A34B1', type:'solvent'},
    {id: '55', colorName: 'PARLITE BLUE KM 40 - 97', ci: 'Solvent Blue 122', tag:"blue", appl: 'plastic', color:'#0C0083', type:'solvent'},
    {id: '56', colorName: 'PARLITE BLUE KM 40 - 103', ci: 'Solvent Blue 128', tag:"blue", appl: 'plastic', color:'#0132CC', type:'solvent'},
    {id: '57', colorName: 'PARLITE VIOLET KM 30 - 38', ci: 'Solvent Violet 13', tag:"violet", appl: 'plastic', color:'#1A127D', type:'solvent'},
    {id: '58', colorName: 'PARLITE VIOLET KM 30 - 39', ci: 'Solvent Violet 14', tag:"violet", appl: 'plastic', color:'#DC007C', type:'solvent'},
    {id: '59', colorName: 'PARLITE VIOLET KM 30 - 62', ci: 'Solvent Violet 37', tag:"violet", appl: 'plastic', color:'#2B153B', type:'solvent'},
    {id: '60', colorName: 'PARLITE VIOLET KM 30 - 63', ci: 'Solvent Violet 38', tag:"violet", appl: 'plastic', color:'#CC0098', type:'solvent'},
    {id: '61', colorName: 'PARLITE BLACK KM RD', ci: 'BLEND', tag:"black", appl: 'plastic', color:'#000000', type:'solvent'},
    {id: '62', colorName: 'PARLITE BRIGHTENER KPB', ci: 'OB 140', tag:"black", appl: 'plastic', color:'#000000', type:'solvent'},
    {id: '63', colorName: 'OPTICAL BRIGHTENER 9800', ci: 'P.Y. 101', tag:"black", appl: 'plastic', color:'#000000', type:'solvent'},
    {id: '64', colorName: 'BLENDED BLACK', ci: '', tag:"black", appl: 'plastic', color:'#000000', type:'solvent'},
    {id: '65', colorName: 'PARCYANINE GREEN PPN (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '66', colorName: 'PARCYANINE GREEN P 2000 E (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '67', colorName: 'PARCYANINE GREEN PPBB/ 6100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '68', colorName: 'PARCYANINE GREEN P 2000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '69', colorName: 'PARCYANINE GREEN P 2100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '70', colorName: 'PARCYANINE GREEN P 2200 Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '71', colorName: 'PARCYANINE GREEN P 2200 B (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '72', colorName: 'PARCYANINE GREEN P 3000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '73', colorName: 'PARCYANINE GREEN P 3800 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '74', colorName: 'PARCYANINE GREEN P 2700 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '75', colorName: 'PARCYANINE GREEN P 4000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '76', colorName: 'PARCYANINE GREEN PPYG (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '77', colorName: 'PARCYANINE GREEN PPYG-I (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '78', colorName: 'PARCYANINE GREEN P 3200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '79', colorName: 'PARCYANINE GREEN P 2500Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '80', colorName: 'PARCYANINE GREEN P 2000H (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '81', colorName: 'PARCYANINE GREEN P 2000HS (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '82', colorName: 'PARCYANINE GREEN P-6200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '83', colorName: 'PARCYANINE GREEN P 6300 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'plastic', color:'#00785F', type:'pigment'},
    {id: '65.1', colorName: 'PARCYANINE GREEN PPN (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '66.1', colorName: 'PARCYANINE GREEN P 2000 E (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '67.1', colorName: 'PARCYANINE GREEN PPBB/ 6100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '68.1', colorName: 'PARCYANINE GREEN P 2000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '69.1', colorName: 'PARCYANINE GREEN P 2100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '70.1', colorName: 'PARCYANINE GREEN P 2200 Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '71.1', colorName: 'PARCYANINE GREEN P 2200 B (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '72.1', colorName: 'PARCYANINE GREEN P 3000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '73.1', colorName: 'PARCYANINE GREEN P 3800 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '74.1', colorName: 'PARCYANINE GREEN P 2700 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '75.1', colorName: 'PARCYANINE GREEN P 4000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '76.1', colorName: 'PARCYANINE GREEN PPYG (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '77.1', colorName: 'PARCYANINE GREEN PPYG-I (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '78.1', colorName: 'PARCYANINE GREEN P 3200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '79.1', colorName: 'PARCYANINE GREEN P 2500Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '80.1', colorName: 'PARCYANINE GREEN P 2000H (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '81.1', colorName: 'PARCYANINE GREEN P 2000HS (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '82.1', colorName: 'PARCYANINE GREEN P-6200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '83.1', colorName: 'PARCYANINE GREEN P 6300 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'paint', color:'#00785F' , type: 'pigment'},
    {id: '65.2', colorName: 'PARCYANINE GREEN PPN (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '66.2', colorName: 'PARCYANINE GREEN P 2000 E (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '67.2', colorName: 'PARCYANINE GREEN PPBB/ 6100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '68.2', colorName: 'PARCYANINE GREEN P 2000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '69.2', colorName: 'PARCYANINE GREEN P 2100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '70.2', colorName: 'PARCYANINE GREEN P 2200 Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '71.2', colorName: 'PARCYANINE GREEN P 2200 B (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '72.2', colorName: 'PARCYANINE GREEN P 3000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '73.2', colorName: 'PARCYANINE GREEN P 3800 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '74.2', colorName: 'PARCYANINE GREEN P 2700 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '75.2', colorName: 'PARCYANINE GREEN P 4000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '76.2', colorName: 'PARCYANINE GREEN PPYG (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '77.2', colorName: 'PARCYANINE GREEN PPYG-I (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '78.2', colorName: 'PARCYANINE GREEN P 3200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '79.2', colorName: 'PARCYANINE GREEN P 2500Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '80.2', colorName: 'PARCYANINE GREEN P 2000H (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '81.2', colorName: 'PARCYANINE GREEN P 2000HS (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '82.2', colorName: 'PARCYANINE GREEN P-6200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '83.2', colorName: 'PARCYANINE GREEN P 6300 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'ink', color:'#00785F' , type: 'pigment'},
    {id: '65.3', colorName: 'PARCYANINE GREEN PPN (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '66.3', colorName: 'PARCYANINE GREEN P 2000 E (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '67.3', colorName: 'PARCYANINE GREEN PPBB/ 6100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '68.3', colorName: 'PARCYANINE GREEN P 2000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '69.3', colorName: 'PARCYANINE GREEN P 2100 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '70.3', colorName: 'PARCYANINE GREEN P 2200 Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '71.3', colorName: 'PARCYANINE GREEN P 2200 B (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '72.3', colorName: 'PARCYANINE GREEN P 3000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '73.3', colorName: 'PARCYANINE GREEN P 3800 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '74.3', colorName: 'PARCYANINE GREEN P 2700 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '75.3', colorName: 'PARCYANINE GREEN P 4000 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '76.3', colorName: 'PARCYANINE GREEN PPYG (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '77.3', colorName: 'PARCYANINE GREEN PPYG-I (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '78.3', colorName: 'PARCYANINE GREEN P 3200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '79.3', colorName: 'PARCYANINE GREEN P 2500Y (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '80.3', colorName: 'PARCYANINE GREEN P 2000H (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '81.3', colorName: 'PARCYANINE GREEN P 2000HS (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '82.3', colorName: 'PARCYANINE GREEN P-6200 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '83.3', colorName: 'PARCYANINE GREEN P 6300 (P.G. 7)', ci: 'P.G. 7', tag:"green", appl: 'textile', color:'#00785F' , type: 'pigment'},
    {id: '84', colorName: 'PARCYANINE GREEN P 1000 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'plastic', color:'#018A5C' , type: 'pigment'},
    {id: '85', colorName: 'PARCYANINE GREEN P 1100 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'plastic', color:'#018A5C' , type: 'pigment'},
    {id: '86', colorName: 'PARCYANINE GREEN P 1200 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'plastic', color:'#018A5C' , type: 'pigment'},
    {id: '87', colorName: 'PARCYANINE GREEN P-1300 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'plastic', color:'#018A5C' , type: 'pigment'},
    {id: '84.1', colorName: 'PARCYANINE GREEN P 1000 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'paint', color:'#018A5C' , type: 'pigment'},
    {id: '85.1', colorName: 'PARCYANINE GREEN P 1100 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'paint', color:'#018A5C' , type: 'pigment'},
    {id: '86.1', colorName: 'PARCYANINE GREEN P 1200 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'paint', color:'#018A5C' , type: 'pigment'},
    {id: '87.1', colorName: 'PARCYANINE GREEN P-1300 (P.G. 36)', ci: 'P.G. 36', tag:"green", appl: 'paint', color:'#018A5C' , type: 'pigment'},
    {id: '88', colorName: 'PARCYANINE BLUE 7000 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '89', colorName: 'PARCYANINE BLUE 7100 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '90', colorName: 'PARCYANINE BLUE 7200 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '91', colorName: 'PARCYANINE BLUE 3000 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '92', colorName: 'PARCYANINE BLUE 3100 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '93', colorName: 'PARCYANINE BLUE 5000 BLUER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '94', colorName: 'PARCYANINE BLUE 5100 REDDER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '95', colorName: 'PARCYANINE BLUE 5300 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '96', colorName: 'PARCYANINE BLUE 7500 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '97', colorName: 'PARCYANINE BLUE 6100 (BLUE 15.4)', ci: 'BLUE 15.4', tag:"blue", appl: 'plastic', color:'#2E5485' , type: 'pigment'},
    {id: '88.1', colorName: 'PARCYANINE BLUE 7000 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '89.1', colorName: 'PARCYANINE BLUE 7100 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '90.1', colorName: 'PARCYANINE BLUE 7200 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '91.1', colorName: 'PARCYANINE BLUE 3000 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '92.1', colorName: 'PARCYANINE BLUE 3100 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '93.1', colorName: 'PARCYANINE BLUE 5000 BLUER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '94.1', colorName: 'PARCYANINE BLUE 5100 REDDER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '95.1', colorName: 'PARCYANINE BLUE 5300 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '96.1', colorName: 'PARCYANINE BLUE 7500 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '97.1', colorName: 'PARCYANINE BLUE 6100 (BLUE 15.4)', ci: 'BLUE 15.4', tag:"blue", appl: 'paint', color:'#2E5485' , type: 'pigment'},
    {id: '88.2', colorName: 'PARCYANINE BLUE 7000 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '89.2', colorName: 'PARCYANINE BLUE 7100 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '90.2', colorName: 'PARCYANINE BLUE 7200 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '91.2', colorName: 'PARCYANINE BLUE 3000 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '92.2', colorName: 'PARCYANINE BLUE 3100 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '93.2', colorName: 'PARCYANINE BLUE 5000 BLUER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '94.2', colorName: 'PARCYANINE BLUE 5100 REDDER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '95.2', colorName: 'PARCYANINE BLUE 5300 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '96.2', colorName: 'PARCYANINE BLUE 7500 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '97.2', colorName: 'PARCYANINE BLUE 6100 (BLUE 15.4)', ci: 'BLUE 15.4', tag:"blue", appl: 'ink', color:'#2E5485' , type: 'pigment'},
    {id: '88.3', colorName: 'PARCYANINE BLUE 7000 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '89.3', colorName: 'PARCYANINE BLUE 7100 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '90.3', colorName: 'PARCYANINE BLUE 7200 (BLUE 15)', ci: 'BLUE 15', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '91.3', colorName: 'PARCYANINE BLUE 3000 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '92.3', colorName: 'PARCYANINE BLUE 3100 (BLUE 15.1)', ci: 'BLUE 15.1', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '93.3', colorName: 'PARCYANINE BLUE 5000 BLUER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '94.3', colorName: 'PARCYANINE BLUE 5100 REDDER (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '95.3', colorName: 'PARCYANINE BLUE 5300 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '96.3', colorName: 'PARCYANINE BLUE 7500 (BLUE 15.3)', ci: 'BLUE 15.3', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '97.3', colorName: 'PARCYANINE BLUE 6100 (BLUE 15.4)', ci: 'BLUE 15.4', tag:"blue", appl: 'textile', color:'#2E5485' , type: 'pigment'},
    {id: '98', colorName: 'VAT BLUE 4', ci: 'PARNON BLUE 500', tag:"blue", appl: 'textile', color:'#2E5485', type: 'vat'},
    {id: '99', colorName: 'VAT BLUE 6', ci: 'PARNON BLUE 600', tag:"blue", appl: 'textile', color:'#2E5485', type: 'vat'},
    
]
export const rndData = {
    titleOne: 'Diverse Product Range',
    contentOne: "Parshwnath's unique strength lies in its continuous and relentless R&D efforts to develop and deliver customized products, which has led to an extensive product portfolio for Parshwanath.",

    titleTwo: 'R&D leading to Quality',
    contentTwo: 'Through extensive Research & Development and strict quality control, Parshwnath ensures that the variance level of its product will be less than 0.7 delta E.'
}

export const HeroCaption = {
    about: 'Globally renowned solvent dyes and pigments manufacturer.',
    contact: '',
    pigment: 'We can help you find the perfect product for your application.',
}

export const AboutMain = {
    vision: 'The vision of our company is to be the leading manufacturer of solvent dyes and pigments in the world, providing the best quality products to our customers.',
    mission: 'To thrive and create new and innovative products that add value to our customer base around the world, whilst being an environmentally sustainable company.'
}