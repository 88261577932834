import React from 'react'
import stock from '../../assets/images/stock2.png'
import './AboutStory.scss'

function AboutStory() {
  return (
    <div className='ab-story'>
        <div className="container-custom-large">
            <div className="ab-story-wrapper">
                <div className="caption">
                    <p>
                        Know Us
                    </p>
                </div>

                <div className="title">
                    <h2>
                        Our Story
                    </h2>
                </div>

                <div className="content">
                    <p>
                    Parshwanath Dyechem is one of the most successful companies in the pigment and dye manufacturing industry. We have been providing quality products to our customers for more than three decades.  The growth we experienced throughout the years has given us a better understanding of the market and customer needs, which led us to open our first manufacturing plant in 1992, producing Phthalocyanine Green as our first product. Parshwanath operates several manufacturing plants in Gujarat. Since 1981, we've endeavored to provide high-quality products and outstanding customer service in the corporate area.
                    
                    Parshwnath's management skills, customer base in different regions and availability of various products make it a standout supplier in the market.
                    </p>
                </div>

                <div className="image-wrapper">
                    <img src={stock} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutStory