import React, {useEffect} from 'react'
import './HomeHero.scss';
import {Row, Col} from 'react-bootstrap'
import circle from '../../assets/images/circle.png'
import heroImgOne from '../../assets/images/hero-image-one.png'
import heroImgTwo from '../../assets/images/hero-image-two.png'
import heroDots from '../../assets/images/hero-dots.png'
// import { Gradient } from "https://gist.githack.com/jordienr/64bcf75f8b08641f205bd6a1a0d4ce1d/raw/35a5c7c1ddc9f97ec84fe7e1ab388a3b726db85d/Gradient.js";
// import'../canvasgradient.js';


function HomeHero() {

    useEffect(()=>{
        import("https://gist.githack.com/jordienr/64bcf75f8b08641f205bd6a1a0d4ce1d/raw/35a5c7c1ddc9f97ec84fe7e1ab388a3b726db85d/Gradient.js").then(( {Gradient} )=> {
            // console.log(Gradient)
            const gradient = new Gradient();
            gradient.initGradient("#gradient-canvas");
        })
    })

  return (
    <div className="home-hero">
        
        <canvas id="gradient-canvas"></canvas>
         <div className='container-custom-large'>
            <div className="home-hero-wrapper">
                <Row>
                    <Col xs={12} md={8}>
                        <div className="home-content">
                            <div className="caption">
                                <p>Parshwanath</p>
                            </div>
                            <div className="title">
                                <h2>
                                    Leading Manufacturers 
                                    of Industrial Pigments
                                    And <i style={{color: '#A7D309'} }>Dyes</i>
                                </h2>
                            </div>

                            <div className="end-caption">
                                <img className='circle' src={circle} alt="" />
                                <p>
                                Delivering quality products in the industry since 1992.
                                </p>
                            </div> 
                        </div>  

                        
                        
                    </Col>

                    <Col xs={12} md={4}>   
                        <div className="home-hero-images">
                            <div className="image-one">
                                <img src={heroImgOne} alt="" />
                            </div>
                            <div className="image-two">
                                <img src={heroImgTwo} alt="" />
                            </div>
                            <img className='image-abs' src={heroDots} alt="" />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>   
  )
}

export default HomeHero