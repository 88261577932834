import React from 'react'
import './Hero.scss'

function Hero(props) {
  return (
    <div className='hero'>
        <div className="container-custom-large">
            <div className="hero-wrapper">
                <div className="hero-content">
                    <div className="title">
                        <h2>{props.title}</h2>
                    </div>
                    <div className="caption">
                        <p>
                            {props.caption}
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Hero