import React, { useEffect, useState } from 'react'
import Estimate from '../components/Estimate/Estimate'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Hero from '../components/Hero/Hero'
import { boxes, HeroCaption } from '../components/Data'
import PigmentBox from '../components/PigmentBox/PigmentBox'
import { Col, Row } from 'react-bootstrap'
import '../components/Pigments.scss'

function Pigments() {

  const [tag, setTag] = useState('all');
  // const [active, setActive] = useState(false);
  const [filteredBoxes, setFilteredBoxes] = useState([]);

  useEffect(()=>{
    tag ==='all' ? setFilteredBoxes(boxes) : setFilteredBoxes(boxes.filter(box => box.tag === tag) )
  },[tag])

  return (
    <div>
        <Header/>
        <Hero title="Pigment Finder" caption={HeroCaption.pigment}/>
        
        <div className='filters container-custom-large'>

          <div className={`filter-item `} onClick={ () => { setTag('all')} }>
            <h4>All</h4>
          </div>

          <div className={`filter-item`} onClick={ () => {setTag('yellow')} }>
            <h4>Yellow</h4>
          </div>

          <div className="filter-item" onClick={ () => setTag('red') }>
            <h4>Red</h4>
          </div>

          <div className="filter-item" onClick={ () => setTag('blue') }>
            <h4>Blue</h4>
          </div>

        </div>

        <div className='container-custom-large'>
          <Row>
              {filteredBoxes.map(box => 
                <Col xs={6} md={3}>
                  <PigmentBox name={box.colorName} color={box.color}/>
                </Col>
                )}
          </Row>
        </div>

        <Estimate/>
        <Footer/>
    </div>
  )
}

export default Pigments